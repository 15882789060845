<template>
	<header ref="headerRef" :class="{ scrolled: scrolled, bannerHeader: topBannerText && topBannerLink }">
		<a v-if="topBannerText && topBannerLink" :href="topBannerLink.Url" :target="topBannerLink.Target" style="text-decoration: none" class="banner_link">
			<div class="banner">
				<div class="banner_content single">
					<div class="banner_text" v-html="topBannerText">					
					</div>
					<div class="img_container">
						<img width="30" height="35" src="../assets/images/rosa-bandet-iconi.png">
					</div>
				</div>
               
			</div>
		</a>
		<div class="section-content-wrapper">
			<nav-primary :scrolled="scrolled" :class="{ scrolled: scrolled }" />
			<nav-mobile
				:scrolled="scrolled"
				:close-menu="closeMenu"				
				:class="{ scrolled: scrolled, bannerHeader: topBannerText && topBannerLink }"
			/>
		</div>
	</header>
</template>
<script>
export default {
	name: 'Nav',
	components: {
		'nav-primary': () => import('@/components/NavPrimary.vue'),
		'nav-mobile': () => import('@/components/NavMobile.vue'),
	},
	props: {
		topBannerText: {
			type: String,
			required: false,
			default: '',
		},
		topBannerLink: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			scrolled: false,
			closeMenu: false,
		}
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
		window.addEventListener('resize', this.handleResize)
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		handleScroll() {
			this.scrolled = window.scrollY > 0
			if (this.scrolled) {
				this.bgcolor = '#fff'
			} else {
				this.bgcolor = ''
			}
		},
		handleResize() {
			if (window.innerWidth > 1023) {
				this.closeMenu = true
				document.body.classList.remove('modal-open')
				this.$refs.headerRef.classList.remove('menu-open')
			} else if (window.innerWidth < 1023) {
				this.closeMenu = false
			}
		},
	},
}
</script>
<style scoped lang="scss">
header {
	@include default-padding-sides;
	width: 100%;
	height: 87px;
	position: fixed;
	top: 0;
	z-index: 999;

	@include min-width(medium) {
		transition: all 0.25s ease;
	}

	&.menu-open {
		background-color: #343735;
	}

	&.bannerHeader {
		height: 137px;
		padding: 0px;
	}

	@media (max-width: 375px) {
		&.bannerHeader {
			height: 217px !important;
		}
	}

	@include max-width(mobile) {
		&.bannerHeader {
			height: 191px;
		}
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		&.bannerHeader {
			height: 140px;
		}
	}
}
.scrolled {
	background-color: #fff;
	color: #000;
}

.banner {
	text-align: center;
	min-height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	color: #fff;
	background: $color-lightpink;
}

.banner .banner_content {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0 50px;
	display: grid;
	place-items: center;
	position: relative;
}

.banner_text {
	color: #000000;
}

.banner .img_container {
	display: grid;
	place-items: center;
	position: absolute;
	right: 5px;
}
</style>
